<template>
  <div class="container pt-5 mt-5" v-if="selectedEvent">
    <div class="text-center"><img class="poster" :src="selectedEvent.poster"/></div>
    <form @submit.prevent="signupList" method="POST">
      <p class="text-danger" v-if="invalidForm">Por favor, rellene todos los campos</p>
      <p class="text-success" v-if="success">¡Ya estás apuntado en lista!</p>
      <div class="form-group">
        <label for="event">Evento</label>
        <select class="form-control" id="event" name="event" v-model="form.eventId" required v-if="events">
          <option v-for="event in events" :value="event.id" :key="event.id">{{ event.name }}</option>
        </select>
      </div>
      <div class="form-group" v-if="isRequiredField('name')">
        <label for="name">Nombre y apellidos</label>
        <input type="text" class="form-control" id="name" name="name" v-model="form.name" required>
      </div>
      <div class="form-group" v-if="isRequiredField('dni')">
        <label for="dni">DNI</label>
        <input type="text" class="form-control" id="dni" name="dni" v-model="form.dni" required>
      </div>
      <div class="form-group" v-if="isRequiredField('email')">
        <label for="email">Email</label>
        <input type="text" class="form-control" id="email" name="email" v-model="form.email" required>
      </div>
      <div class="form-group" v-if="isRequiredField('instagram')">
        <label for="email">Instagram</label>
        <input type="text" class="form-control" id="instagram" name="instagram" v-model="form.instagram" required>
      </div>

      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block" :disabled="disableBtn">Apuntarme en lista</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      events: null,
      invalidForm: false,
      success: false,
      form: {
        name: null,
        eventId: null,
        dni: null,
        email: null,
        instagram: null
      },
      disableBtn: false
    }
  },
  computed: {
    selectedEvent () {
      if (!this.events) {
        return null
      }
      return this.events.find(event => event.id === this.form.eventId)
    }
  },
  mounted () {
    const eventId = this.$route.params.eventId
    this.axios.get(`/events${eventId ? `/${eventId}` : ''}`, { params: { owner: this.$store.state.domain }}).then(response => {
      if (!response.data || response.data.length == 0) {
        if (!eventId) {
          this.axios.get('/events', { params: { owner: this.$store.state.domain, eventType: 1, lists: false }}).then(response => {
            if (response.data && response.data.length > 0) {
              this.$router.push(`/entradas/${response.data[0].id}`)
            } else {
              this.$router.push('/home')
            }
          })
        } else {
          this.$router.push(`/entradas${eventId ? `/${eventId}` : ''}`)
        }
      } else {
        if (eventId) {
          this.events = [response.data]
        } else {
          this.events = response.data
        }
        this.form.eventId = this.events[0].id
      }
    })
  },
  methods: {
    signupList () {
      this.disableBtn = true
      if ((this.isRequiredField('name') && !this.form.name) || (!this.form.eventId) || (this.isRequiredField('dni') && !this.form.dni) || (this.isRequiredField('email') && !this.form.email) || (this.isRequiredField('instagram') && !this.form.instagram)) {
        this.invalidForm = true
      } else {
        this.axios.post('/listEntries', this.form).then((response) => {
          if (response.data) {
            this.form.name = null
            this.form.dni = null
            this.form.email = null
            this.form.instagram = null
            this.success = true
          } else {
            alert('Ya está apuntado en esta lista')
          }
          this.disableBtn = false
        })
      }
    },
    isRequiredField (fieldName) {
      if (this.events) {
        const event = this.selectedEvent
        if (!event.tickeT_FIELDS) { return true }
        return event.tickeT_FIELDS.split(',').includes(fieldName)
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.poster {
  max-height: 60vh;
}

</style>